<template>
  <div>
    <div class="text-h4 pt-5 mb-5">Редактирование страницы FAQ</div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            scrollable
            persistent
            :retain-focus="false"
            max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Создать
              </v-btn>
            </template>
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span
                  class="text-h5 mr-2"
                  v-html="
                    editedIndex === -1 ? 'Создать вопрос' : 'Именить вопрос'
                  "
                ></span>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" class="py-0">
                    <v-select
                      ref="editedItem.category_id"
                      v-model="editedItem.category_id"
                      :items="typeFaq"
                      :rules="[rules.required]"
                      label="Категория"
                      item-text="name"
                      item-value="id"
                      item-color="secondary"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12" class="py-0 mb-4">
                    <v-tabs v-model="tab">
                      <v-tab v-for="lang in languagesList" :key="lang.iso">
                        <div class="d-flex align-center">
                          <country-flag
                            :country="lang.icon"
                            class="ma-0 elevation-3"
                          />
                          {{ lang.name }}
                        </div>
                      </v-tab>
                      <v-tab-item
                        v-for="lang in languagesList"
                        :key="lang.iso + '_tab_faq'"
                        eager
                        class="pt-3"
                      >
                        <v-text-field
                          :ref="`editedItem.question.${lang.iso}`"
                          v-model="editedItem.question[lang.iso]"
                          label="Вопрос"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :error="errors.name ? true : false"
                          :error-messages="errors.name"
                          @focus="errors.question = null"
                        ></v-text-field>
                        <editor
                          :value="editedItem.answer[lang.iso]"
                          @input="(v) => (editedItem.answer[lang.iso] = v)"
                        />
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <div class="d-inline-block">
                      <v-text-field
                        ref="editedItem.sort"
                        v-model="editedItem.sort"
                        label="Сортировка"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :error="errors.sort ? true : false"
                        :error-messages="errors.sort"
                        @focus="errors.sort = null"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <div class="d-inline-block">
                      <v-switch
                        color="primary"
                        class="mt-0"
                        :ripple="false"
                        v-model="editedItem.active"
                        label="Видимость"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn
                  color="accent"
                  @click="save"
                  v-html="editedIndex === -1 ? 'Создать' : 'Изменить'"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card class="pa-1">
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                  >Удалить вопрос?<br />
                  <span class="accent--text">{{
                    itemCurrentName
                  }}</span></v-card-title
                >

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <div class="d-inline-block">
          <v-switch
            v-model="item.active"
            color="primary"
            class="my-0"
            hide-details
            :ripple="false"
            @change="changeStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import faqSeo from "@/mixins/faq-seo";
import CountryFlag from "vue-country-flag";
import api from "../../../api";
import { mapGetters } from "vuex";
import Editor from "@/components/Editor";
import copyValueObjs from "@/helpers/copyValueObjs";
export default {
  name: "ContentFaq",
  mixins: [rules, slug, faqSeo],
  components: { CountryFlag, Editor },
  data() {
    return {
      loading: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Вопрос",
          align: "start",
          sortable: false,
          value: "question.ru",
        },
        {
          text: "Видимость",
          value: "active",
          sortable: false,
          align: "center",
          width: 80,
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
          width: 75,
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      optionsTable: {},
      errors: {},
      tab: 0,
      editorConfig: {
        allowedContent: true,
      },
      typeFaq: [
        {
          id: 1,
          name: "Общие сведения",
        },
        {
          id: 2,
          name: "TeMa Базовое",
        },
        {
          id: 3,
          name: "TeMa для репетиторов",
        },
        {
          id: 4,
          name: "TeMa для студентов",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      languagesList: "languagesList",
    }),
    itemCurrentName() {
      return this.editedIndex > -1
        ? this.items[this.editedIndex].question?.ru
        : "";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.editedItem = { ...this.createItem() };
    this.getItems();
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      // this.editedItem = {
      //   ...JSON.parse(JSON.stringify(this.items[this.editedIndex])),
      // };
      this.editedItem = copyValueObjs(
        this.createItem(),
        this.items[this.editedIndex]
      );
      await this.$nextTick();
      this.dialog = true;
      this.resetErrors(this.editedItem, "editedItem");
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.createItem()));
        this.editedIndex = -1;
        this.errors = {};
        this.tab = 0;
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.createItem()));
        this.editedIndex = -1;
      });
    },

    async save() {
      let formHasErrors = this.validationObject(this.editedItem, "editedItem");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (this.editedIndex > -1) {
        await this.updateItem();
        this.getItems();
      } else {
        await this.addItem();
        this.getItems();
      }
    },

    async getItems() {
      this.loading = true;
      try {
        const req = await api.faq.list();
        this.items = req.data;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.editedItem;
      try {
        await api.faq.create(data);
        this.$store.dispatch("showSnackbar", {
          msg: `Вопрос создан`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.editedItem;
      try {
        await api.faq.update(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Вопрос изменен`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async removeItem(id) {
      try {
        await api.faq.delete(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Вопрос удален`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    async changeStatus(item) {
      this.editedIndex = this.items.indexOf(item);
      const status = item.active;
      const loadItem = this.items[this.editedIndex];
      this.editedItem = { ...loadItem, active: status };
      await this.updateItem();
      this.close();
    },

    createItem() {
      const keys = ["question", "answer"];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        sort: 1,
        active: true,
      };
    },
  },
};
</script>

<style scoped></style>
